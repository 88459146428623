<template>
    <div class="shop_main noMenu">
        <div class="shop_order_main shopBuyingDetail pt0">
            <userMenu type="6-1" />

            <div class="contain mt14 ml40 mr24">
                <div class="title">
                    <h2>{{infoData.purchase.title}}</h2>
                </div>

                <div class="content">
                    <div class="head">
                        <div class="times">
                            <span class="time">采购发布时间： {{infoData.purchase.createdAt}}</span>
                            <span class="time">报价截止时间： {{infoData.purchase.stopAt}}</span>
                            <span class="time">期望收货时间： {{infoData.purchase.receiptAt}}</span>
                        </div>

                        <div class="times right">
                            <span class="time">采购单号：{{infoData.purchase.code}}</span>
                            <span class="time" v-if="infoData.purchase.isRun === 1">采购单状态： <span>进行中</span></span>
                            <span class="time" v-if="infoData.purchase.isRun === 2">采购单状态： <span>已关闭</span></span>
                            <span class="time" v-if="infoData.purchase.isRun === 3">采购单状态： <span>已过期</span></span>
                        </div>
                    </div>

                    <div class="subTitle">
                        <h1>采购商品</h1>
                    </div>

                    <div class="goodsInfo">
                        <div class="line">
                            <span class="label">商品名称</span>
                            <span class="label">商品规格</span>
                            <span class="label">采购量</span>
                            <span class="label">采购价格</span>
                            <span class="label"></span>
                        </div>
                        <div class="line">
                            <span class="text">{{infoData.purchase.goodName}}</span>
                            <span class="text">{{infoData.purchase.sku}}</span>
                            <span class="text">{{infoData.purchase.num}}{{infoData.purchase.unitName}}</span>
                            <span class="text"><span>¥ {{infoData.purchase.price}}</span> {{getPriceStatus(infoData.purchase.priceStatus)}}</span>
                            <span class="text">
                                <el-image v-if="infoData.purchase.imageUrl"
                                          style="width: 50px; height: 50px"
                                          fit="contain"
                                          :src="imageURL + infoData.purchase.imageUrl"
                                          :preview-src-list="[imageURL + infoData.purchase.imageUrl]">
                                </el-image>
                            </span>
                        </div>
                    </div>

                    <div class="subTitle">
                        <h1>联系方式</h1>
                    </div>

                    <div class="contactDesc">
                        <span class="text">联系人：{{infoData.purchase.contactName}}</span>
                        <span class="text">联系电话：{{infoData.purchase.contactPhone}}</span>
                    </div>

                    <div class="subTitle">
                        <h1>详细说明</h1>
                    </div>

                    <div class="buyDesc">{{infoData.purchase.content}}</div>
                </div>

                <div class="titleElse">
                    <h2>我的报价单</h2>
                </div>

                <div class="offerDetail">
                    <div class="subTitle">
                        <h1>报价单内容</h1>
                    </div>

                    <div class="info">
                        <span class="text">报价标题：{{infoData.quotedPrice.title}}</span>
                        <span class="text">供应数量：{{infoData.quotedPrice.num}}{{infoData.quotedPrice.unitName}}</span>
                        <span class="text">价格说明：￥ {{infoData.quotedPrice.price}} （{{getPriceStatus(infoData.quotedPrice.priceStatus)}}）</span>
                        <div class="line">
                            <span class="text">商品图片：</span>
                            <el-image
                                    v-if="infoData.quotedPrice.imageUrl"
                                    style="width: 124px; height: 124px"
                                    fit="contain"
                                    :src="imageURL + infoData.quotedPrice.imageUrl"
                                    :preview-src-list="[imageURL + infoData.quotedPrice.imageUrl]">
                            </el-image>
                        </div>
                        <span class="text">报价说明：{{infoData.quotedPrice.content}}</span>
                        <span class="text">公司名称：{{infoData.quotedPrice.companyName}}</span>
                        <span class="text">联系人：{{infoData.quotedPrice.contactName}}</span>
                        <span class="text">联系电话：{{infoData.quotedPrice.contactPhone}}</span>
                        <span class="text">预计交货日期：{{infoData.quotedPrice.deliveryAt}}</span>
                    </div>
                </div>

                <div class="footBtn pb32" style="justify-content: center">
                    <el-button @click="$router.go(-1)">返 回</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {getMyOfferInfo} from "../../../../api/shop/supplyBuying";
  import {imageURL} from "../../../../utils/config";

  const userMenu = () => import('../../../../components/layout/shop/userMenu')
  export default {
    name: "shopSupplyBuyingQuotationDetail",
    data () {
      return {
        infoData: {
          purchase: {},
          quotedPrice: {}
        },
        imageURL: imageURL,
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getInfo()
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true
        getMyOfferInfo(this.$route.query.id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.infoData = res.data
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getPriceStatus (status) {
        switch (status) {
          case 1:
            return '可议价';
          case 2:
            return '不可议价';
          case 3:
            return '面议';
        }
      },
    },
    components: {
      userMenu
    }
  }
</script>
